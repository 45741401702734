:root[data-theme="light"] {
  --bg-color: #ffffff;
  --text-color: #333333;
  --header-bg: #f8f9fa;
  --link-color: #0066cc;
  --link-hover-color: #0052a3;
  --code-bg: #f5f5f5;
  --border-color: #e1e1e1;
}

:root[data-theme="dark"] {
  --bg-color: #282c34;
  --text-color: #ffffff;
  --header-bg: #282c34;
  --link-color: #61dafb;
  --link-hover-color: #8ae8ff;
  --code-bg: #1a1e24;
  --border-color: #404040;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: all 0.3s ease;
}

.landing-page {
  height: 100vh;
}

.App-header {
  background-color: var(--header-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

.theme-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 1000;
}

.bouncing-R {
  font-size: 48px;
  font-weight: bold;
  animation: bounce 2s infinite;
  color: var(--text-color);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-20px) rotate(90deg);
  }
  50% {
    transform: translateY(0) rotate(180deg);
  }
  75% {
    transform: translateY(20px) rotate(270deg);
  }
}

.nav-links {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-link {
  color: var(--text-color);
  text-decoration: none;
  padding: 1rem 2rem;
  border: 2px solid var(--text-color);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.blog-link:hover {
  background: var(--text-color);
  color: var(--bg-color);
}

.blogs-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  min-height: 100vh;
}

.blog-section {
  text-align: left;
  background: var(--bg-color);
  padding: 2rem;
  border-radius: 8px;
  color: var(--text-color);
}

.blog-post {
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: var(--header-bg);
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.blog-header:hover {
  background-color: var(--code-bg);
}

.blog-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.expand-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.2s ease;
}

.blog-content {
  max-height: 150px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  position: relative;
  padding: 1.5rem;
}

.blog-content.expanded {
  max-height: 10000px;
  transition: max-height 0.5s ease-in;
}

.blog-preview {
  position: relative;
  padding-bottom: 1rem;
}

.preview-fade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: linear-gradient(transparent, var(--bg-color));
}

.blog-post h1 {
  color: var(--text-color);
  margin-bottom: 1.5rem;
}

.blog-post h2, .blog-post h3 {
  color: var(--text-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.blog-post p {
  margin-bottom: 1.2rem;
  color: var(--text-color);
}

.blog-post code {
  background-color: var(--code-bg);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
  color: var(--text-color);
}

.blog-post a {
  color: var(--link-color);
  text-decoration: none;
}

.blog-post a:hover {
  text-decoration: underline;
  color: var(--link-hover-color);
}

.blog-post strong {
  font-weight: 600;
}

.blog-post ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1.2rem;
}

.blog-post ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
  margin-bottom: 1.2rem;
}

.blog-post li {
  margin-bottom: 0.5rem;
}

.blog-post h1, .blog-post h2, .blog-post h3, .blog-post h4, .blog-post h5, .blog-post h6 {
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.blog-post h1 {
  font-size: 2rem;
}

.blog-post h2 {
  font-size: 1.5rem;
}

.blog-post h3 {
  font-size: 1.25rem;
}